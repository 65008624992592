import { getSectionStyle } from '@/utils'

export default function () {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.slug', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }

  function getParentPost(source) {
    return (get('posts', 'Root.item') || []).find(p => p.slug === source)
  }

  return [
    {
      condition: get('media.cover.length'),
      component: 'Section',
      props: {
        width: 'full',
        style: 'padding: 0;',
      },

      blocks: {
        default: [
          {
            component: 'Slider',
            props: {
              style: 'max-height: 60vh'
            },
            data: get('media.cover'),
          },
        ],
      },
    },
    {
      component: 'Section',
      condition: getParentPost(this.$path('_props.postSlug', this)),
      props: {
        width: 'medium',
        style: 'padding-top:2rem; padding-bottom: 1rem;',
        class: 'section-breadcrumbs-top',
      },
      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              gap: '0rem',
              class: 'article__breadcrumbs',
              alignItems: 'center',
            },
            blocks: {
              default: [
                {
                  component: 'Link',
                  props: {
                    target: '_self',
                    label: getParentPost(this.$path('_props.postSlug', this))?.title,
                    class: 'article__breadcrumbs--link',
                  },
                  data: {
                    href: `/${getParentPost(this.$path('_props.postSlug', this))?.slug}`,
                  },
                },
                {
                  component: 'Text',

                  data: {
                    content: get('title'),
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      condition: get('content'),
      component: 'Section',
      props: {
        width: 'small',
        style: 'padding-top: 3rem; padding-bottom: 2rem;',
      },

      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: `<h1>${get('title')}</h1>${get('content')}`,
            },
          },
        ],
      },
    },
    ...(get('customFields.additional-sections') || []).map((i, idx, arr) => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          captionPlacement: i['image-caption-placement'] || 'inside',
          style: getSectionStyle(i, arr[idx - 1], arr[idx + 1])
        },
      }
    }),

    {
      condition: () => {
        return (get('customFields.icons') || []).filter(i => {
          if (!i) return false
          return i.content || i.icon
        }).length
      },
      component: 'Section',

      props: {
        width: 'medium',
        theme: get('customFields.icons-settings.theme'),
      },

      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              columns: parseInt(get('customFields.icons-settings.columns')) || 4,
              iconBackgroundColor: get('customFields.icons-settings.show-icon-background-color')
                ? get('customFields.icons-settings.icon-background-color')
                : 'inherit',

              borderTheme: get('customFields.icons-settings.border-theme'),
              placement: get('customFields.icons-settings.placement'),
            },
            data: {
              content: get('customFields.icons'),
            },
            variant: get('customFields.icons-settings.variant'),
          },
        ],
      },
    },
    ...(get('customFields.additional-sections-1') || []).map((i, idx, arr) => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          captionPlacement: i['image-caption-placement'] || 'inside',
          style: getSectionStyle(i, arr[idx - 1], arr[idx + 1])
        },
      }
    }),
    {
      condition: get('customFields.address.long') && get('customFields.address.lat'),
      component: 'Section',
      props: {
        width: 'full',
        style: '--section-padding-block: 0rem',
      },
      blocks: {
        default: [
          {
            component: 'Map',
            data: {
              formatted: get('customFields.address.street'),
              coordinates: [get('customFields.address.long'), get('customFields.address.lat')],
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      condition: get('customFields.contact-cta.content') || get('customFields.contact-cta.button'),
      props: {
        class: 'contact-cta-bottom ',
        style: 'padding-block: 3.5rem;',
      },

      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              flexDirection: 'column',
              alignItems: 'center',
              class: 'contact-cta-bottom__content',
            },

            blocks: {
              default: [
                {
                  component: 'Text',
                  condition: get('customFields.contact-cta.content'),
                  data: {
                    content: get('customFields.contact-cta.content'),
                  },
                  props: {
                    textAlign: 'center',
                  },
                },
                {
                  component: 'Button',
                  condition: get('customFields.contact-cta.button'),
                  data: {
                    label: get('customFields.contact-cta.button'),
                    to: { name: 'Contact' },
                    theme: 'primary',
                    tag: 'router-link',
                    icon: ['fas', 'arrow-right'],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ]
}
