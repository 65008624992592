import { TransformTag } from '@/utils'

export default function () {
	const get = (path, source = 'Root.item.posts') => {
		const slug = this.$path('_props.slug', this)
		if (!slug) return

		let data = this.$path(source, this.$store.state) || []
		if (source.includes('posts')) {
			data = data.find(p => p.slug === slug)

			if (!data) return
		}

		return this.$path(path, data)
	}
	return [
		{
			component: 'Section',
			props: {
				width: 'medium',
			},
			blocks: {
				default: [
					{
						component: 'Portal',
						variant: 'project',
						props: {
							hideFilter: true,
							theme: get('customFields.project-portal.theme') || 'card',
							statusFilter: get('customFields.status-filter') || 'sale',
							layout: get('customFields.project-portal.layout'),
							excluded: get('customFields.project-portal.excluded-projects'),
							sortBy: (a, b) => (this.$path('customFields.project-portal-order', a) || 0) - (this.$path('customFields.project-portal-order', b) || 0)
						},
						blocks: {
							top: [
								{
									component: 'Text',
									data: {
										content: TransformTag(get('content')),
									},
									props: {
										style: 'padding-bottom: 3rem;  max-width: 830px; margin-inline: auto',
										class: 'text--medium',
									},
								},
							],
						},
					},
				],
			},
		},
		{
			component: 'Section',
			condition: get('customFields.contact-cta.content') || get('customFields.contact-cta.button'),
			props: {
				class: 'contact-cta-bottom ',
				style: 'padding-block: 3.5rem;',
			},

			blocks: {
				default: [
					{
						component: 'Flex',
						props: {
							flexDirection: 'column',
							alignItems: 'center',
							class: 'contact-cta-bottom__content',
						},

						blocks: {
							default: [
								{
									component: 'Text',
									condition: get('customFields.contact-cta.content'),
									data: {
										content: get('customFields.contact-cta.content'),
									},
									props: {
										textAlign: 'center',
									},
								},
								{
									component: 'Button',
									condition: get('customFields.contact-cta.button'),
									data: {
										label: get('customFields.contact-cta.button'),
										to: { name: 'Contact' },
										theme: 'primary',
										tag: 'router-link',
										icon: ['fas', 'arrow-right'],
									},
								},
							],
						},
					},
				],
			},
		},
	]
}
